// Customizable Area Start
import React from "react";
import { Box, ThemeProvider, createTheme, Container, styled, Typography, CircularProgress, Input, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, TableContainer, ButtonBase, Select, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from "@material-ui/core";
import Header from "../../navigationmenu/src/Header.web";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiPaper: {
            root: {
                fontFamily: "'Montserrat', sans-serif !important"
            },
            rounded: {
                borderRadius: "8px 8px 32px 8px"
            }
        },
        MuiChip: {
            label: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiInput: {
            underline: {
                "&::before": {
                    borderBottom: 'none'
                },
                "&:hover::before": {
                    borderBottom: 'none !important'
                }
            }
        },
        MuiTableCell: {
            root: {
                fontFamily: "'Montserrat', sans-serif !important"
            },
            head: {
                color: "#475569",
                background: "#E5E5FF",
                textAlign: 'center',
                fontWeight: 700
            },
            body: {
                background: 'white'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '10px 20px 30px 20px',
                "@media (max-width: 568px)": {
                    // padding: "15px 20px",
                },
            }
        },
    }
});
import AuditListController, {
    Props
} from "./AuditListController";
import { configJSON } from "./TaskController";
import { Autocomplete, Pagination, PaginationItem } from "@material-ui/lab";

// Customizable Area End

export default class AuditList extends AuditListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    DatePickerRange = () => {
        return <DatePickerContainer>
            <Box className="select-date-item">

                <Typography className="title">{configJSON.fromDate}</Typography>
                <DatePicker
                    selected={this.state.fromDate}
                    dateFormat="MMM dd, yyyy"
                    className="dateInput"
                    data-test-id="dateFilterFrom"
                    maxDate={this.state.toDate ?? new Date()}
                    popperPlacement="bottom-start"
                    placeholderText="mm dd, yyyy"
                    onChange={(date: Date) => this.handleChangeFromDate(date)}
                    customInput={<Input disableUnderline endAdornment={<CalendarTodayRoundedIcon className="icon-calendar" />} />}
                />
            </Box>
            <Box className="select-date-item">
                <Typography className="title">{configJSON.toDate}</Typography>
                <Box style={{ ...styles.flexBox, gap: 10, alignItems: 'center' }}>
                    <DatePicker
                        selected={this.state.toDate}
                        onChange={(date: Date) => this.handleChangeToDate(date)}
                        dateFormat="MMM dd, yyyy"
                        className="dateInput"
                        data-test-id="dateFilterTo"
                        maxDate={new Date()}
                        minDate={this.state.fromDate}
                        popperPlacement="bottom-start"
                        placeholderText="mm dd, yyyy"
                        customInput={<Input disableUnderline endAdornment={<CalendarTodayRoundedIcon className="icon-calendar" />} />}
                    />
                    {(this.state.fromDate || this.state.toDate) &&
                        <HighlightOffRoundedIcon data-test-id='btn-clear-date' onClick={this.removeDateRangePicker} style={{ color: '#F87171' }} />}
                </Box>
            </Box>
        </DatePickerContainer>
    }

    StatusBar = (status: string) => {
        let { color, backgroundColor, text, smallText } = this.getStatusStyle(status)

        return <StatusContainer style={{ backgroundColor, color }}>
            <Typography className="big-text" style={{ color }}>{text}</Typography>
            <Typography className="small-text" style={{ color }}>{smallText}</Typography>
        </StatusContainer>
    }

    FilterSelect = (id: string,
        options: any, value: any,
        onChange: (value: any) => void) => {
        return <CustomSelect
            labelId="demo-customized-select-label"
            disableUnderline
            data-test-id={"container-" + id}
            className="dialog-select"
            MenuProps={{
                TransitionProps: { style: { padding: "0 4px", marginTop: 20, borderRadius: 8, maxHeight: 250 } },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                getContentAnchorEl: null,
            }}
            disabled={id==="Status" && this.state.userRole.group!=='evermore'}
            displayEmpty
            multiple
            renderValue={(value: any) => { return value && value.length > 0 ? value.join(', ') : id }}
            value={value}
            IconComponent={ArrowDropDownRoundedIcon}
        >
            {options.length > 0 ? options.map((item: any) => {
                return <SelectItem
                    key={item}
                    data-test-id={"test-" + id}
                    onClick={() => onChange(item)}>
                    <Checkbox checked={value.includes(item)} style={{ color: "#152764" }} />
                    <Typography>{item}</Typography>
                </SelectItem>
            }) : <Typography>{configJSON.noRecord}</Typography>}
        </CustomSelect>
    }

    CustomAutoComplete = (id: string, listItem: Array<any>, value: any, onChange: (value: any) => void) => {
        const displayOption = (option: any) => {
            let value = "";
            if(id === "Account Name") return option.name
            if(option.email) value = option.email;
            if(option.full_name) value = option.full_name;
            return value;
        }
        return <AutoCompleteContainer>
            <MyAutoComplete
                key={id}
                options={listItem}
                getOptionLabel={(option) => displayOption(option)}
                renderOption={(option) => <React.Fragment>
                    <Typography style={{wordBreak: 'break-word'}}>{displayOption(option)}</Typography>
                </React.Fragment>}
                renderInput={(params) => <TextField {...params} placeholder={id} InputProps={{ ...params.InputProps, disableUnderline: true }} />}
                id={id}
                debug={true}
                data-test-id={'select-' + id}
                onChange={(event, newValue) => {
                    onChange(newValue)
                }}
                className="dialog-select"
                value={
                    (value === undefined ||
                    value === null ||
                    listItem.length === 0) ? '' : value}
                popupIcon={<ArrowDropDownRoundedIcon style={{ marginRight: -8 }} />}
            />
        </AutoCompleteContainer>
    }
    FilterGroup = () => {
        const { selectedAuditNumber,
            listStatus, selectedStatus,
            listAccountName, selectedAccountName,
            listAuditor, selectedAuditor, userRole
        } = this.state
        const filteredStatus = userRole.group === 'evermore' ? listStatus : listStatus.filter(item => item === "Completed")
        return <FilterContainer>
            <FilterMainContent>
            <CustomInput
                data-test-id="txtAuditNumber"
                placeholder={"Audit Number"}
                disableUnderline
                onChange={(event: any) => this.onChangeAuditNumber(event.target.value)}
                value={selectedAuditNumber}
                type="text"
            />
            {this.CustomAutoComplete('Account Name', listAccountName, selectedAccountName, this.onChangeAccountName)}
            {this.FilterSelect('Status', filteredStatus, selectedStatus, this.onChangeListStatus)}
            {this.CustomAutoComplete('Assigned To', listAuditor, selectedAuditor, this.onChangeAuditor)}
            <DateSortContainer data-test-id="sort-date-created" onClick={this.onChangeDateCreated}>
                <Typography>{configJSON.dateCreated}</Typography>
                <img src={this.getDateCreatedIcon()}  style={{opacity: this.state.isDateCreatedAsc === null ? 0.5 : 1}} alt="sort date created" />
            </DateSortContainer>
            <DateSortContainer data-test-id="sort-date-completed" onClick={this.onChangeDateCompleted}>
                <Typography>{configJSON.dateCompleted}</Typography>
                <img src={this.getDateCompletedIcon()} style={{opacity: this.state.isDateCompletedAsc === null ? 0.5 : 1}} alt="sort date created" />
            </DateSortContainer>
            </FilterMainContent>
            
           {this.isShowButtonCancel() && 
            <Box className="button-group">
                <CustomButton data-test-id="btn-cancel" className="button-remove-filter" onClick={this.handleCancelFilter}>
                    <ClearRoundedIcon />
                </CustomButton>
            </Box>}
        </FilterContainer>
    }

    CustomTable = () => {
        const { tableData, width, userRole } = this.state;
        const lowerCaseUserRole = userRole.role?.toLowerCase()
        const isSmall = width < 820 || (width > 960 && width < 1070);

        return <CustomTableContainer>
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell size="small" style={styles.stickyItem}>
                            {isSmall ? "#" : configJSON.auditNumber}
                        </TableCell>
                        <TableCell>
                            {configJSON.accountName}
                        </TableCell>
                        {!isSmall &&
                            <TableCell>
                                {configJSON.status}
                            </TableCell>}
                        <TableCell>
                            {configJSON.assignedTo}
                        </TableCell>
                        <TableCell>
                            {configJSON.dateCreated}
                        </TableCell>
                        <TableCell>
                            {configJSON.dateCompleted}
                        </TableCell>
                        {lowerCaseUserRole  === 'all access user' &&
                         <TableCell style={{ ...styles.stickyItem, left: 'auto', right: 0 }}>
                        </TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody className="table-body">
                    {tableData.length > 0 ?
                        tableData.map(rowItem => {
                            const rowAttributes = rowItem.attributes
                            const { backgroundColor, text } = this.getStatusStyle(rowAttributes.status);
                            return <TableRow data-test-id="item-row" className="table-row" key={rowItem.id} style={{ cursor: 'pointer' }} onClick={() => this.gotoAuditInfo(rowItem.id)}>
                                {isSmall ?
                                    <Tooltip arrow placement="bottom-start" title={text}>
                                        <TableCell align="center" className="table-cell" size="small"
                                            style={{ ...styles.stickyItem, borderLeftColor: backgroundColor }}>{rowItem.id}</TableCell>
                                    </Tooltip> :
                                    <TableCell align="center" className="table-cell" size="small"
                                        style={{ ...styles.stickyItem, borderLeftColor: backgroundColor }}>{rowItem.id}</TableCell>
                                }
                                <TableCell align="center">{rowAttributes.account_name}</TableCell>
                                {!isSmall && <TableCell align="center">{this.StatusBar(rowAttributes.status)}</TableCell>}
                                <TableCell align="center">{rowAttributes.assigned_to}</TableCell>
                                <TableCell align="center">{rowAttributes.date_created}</TableCell>
                                <TableCell align="center">{rowAttributes.date_completed}</TableCell>
                                {lowerCaseUserRole === 'all access user' &&
                                <TableCell align="center" data-test-id="editAuditPopup" onClick={(event) => { this.openEditModal(event, rowItem) }} style={{ ...styles.stickyItem, right: 0, left: 'auto' }}>
                                    <ButtonBase >
                                        <EditRoundedIcon />
                                    </ButtonBase>
                                </TableCell>}
                            </TableRow>
                        }) :
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                                <Typography>{configJSON.noRecord}</Typography>
                            </TableCell>
                        </TableRow>}
                </TableBody>
            </Table>
        </CustomTableContainer>
    }

    DialogEditAudit = () => {
        const { listAuditor, edittedRecord } = this.state
        const {audit_number} = edittedRecord?.attributes
        const {auditor} = edittedRecord
        return <DialogEdit
            fullWidth
            open={this.state.isOpenModal}
            onClose={this.handleCloseModal}>
            <DialogTitle>
                <ClearRoundedIcon className="icon-close" onClick={this.handleCloseModal} />
            </DialogTitle>
            <DialogContent>
                <Typography className="title">Audit - #{audit_number}</Typography>
                <Box className="select-group">
                    <Box>
                        <Typography className="select-label">{configJSON.assignedTo}</Typography>
                        {this.CustomAutoComplete("Assigned To", listAuditor, auditor, this.handleEditAssignTo)}
                    </Box>
                   
                    <CustomButton onClick={this.handleDeleteRecord} className="button-cancel" data-test-id="btn-deleteAudit">
                        <Typography>{configJSON.deleteAudit}</Typography>
                    </CustomButton>
                </ Box>
            </DialogContent>
            <DialogActions >
                <CustomButton data-test-id="btn-closeModal" className="button-cancel" onClick={this.handleCloseModal}>
                    <Typography>Cancel</Typography>
                </CustomButton>
                <CustomButton data-test-id="btn-editSave" className="gradient" onClick={this.handleSaveEditRecord}>
                    <Typography>Save</Typography>
                </CustomButton>
            </DialogActions>
        </DialogEdit>
    }

    NotiToast = () => {
        return <Dialog PaperProps={{style: {position: 'absolute', bottom: 0, borderRadius: '0px 0px 20px 0px'}}} className="noti-toast" open={this.state.isOpenNotiToast} onClose={this.closeNotiToast}>
             <DialogContent style={{display: 'flex', paddingBottom: 20}}>
             {this.state.error ? <CancelIcon style={{ marginRight: 10, color: '#F87171' }} /> : <CheckCircleRoundedIcon style={{ marginRight: 10, color: '#34D399' }} />}
                <Typography style={{fontWeight: 700}}>{this.state.notiMessage}</Typography>
            </DialogContent>
        </Dialog>
    }

    MainUI = () => {
        const {searchKey} = this.state
        return <React.Fragment>
            <TitleGroup>
                {searchKey!==""?<Title>{configJSON.searchResultFor} "{searchKey}"</Title> : <Title className="title">{configJSON.auditList}</Title>}
                {searchKey==="" && this.DatePickerRange()}
            </TitleGroup>
            {this.state.isLoading ?
                <CircularProgress /> :
                <Box>
                    {searchKey==="" && this.FilterGroup()}
                    {this.CustomTable()}
                    <CustomPagination size="small" count={this.state.totalPage} siblingCount={1}
                        onChange={(event, page) => { this.onChangePage(page) }}
                        page={this.state.currentPage}
                        data-test-id="pagination"
                        renderItem={(item) => <PaginationItem {...item} className="pagination-item" />} />
                </Box>
            }
        </React.Fragment>
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <Header navigation={this.props.navigation} searchTerm={this.state.searchKey}>
                <ThemeProvider theme={theme}>
                    <CustomContainer maxWidth={false}>
                        {this.state.isPageLoading ? <CircularProgress style={{ margin: 30 }} /> : this.MainUI()}
                    </CustomContainer>
                {this.DialogEditAudit()}
                {this.NotiToast()}
                </ThemeProvider>
            </Header>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    flexBox: {
        display: "flex",
    },
    stickyItem: {
        position: "sticky",
        left: 0,
        zIndex: 10
    } as React.CSSProperties
}
const TitleGroup = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    alignItems: 'end',
    flexWrap: 'wrap',
    marginBottom: 20
})
const Title = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#0F172A',
    textTransform: 'capitalize',
    marginRight: 100
})

const CustomContainer = styled(Container)({
    background: "#F1F5F9",
    paddingBottom: 20,
    paddingTop: 20,
    overflowY: 'auto',
    position: 'relative',
    height: '100%',
    "& .button-group": {
        display: 'flex',
        gap: 15,
    },

    "& .react-datepicker-popper": {
        zIndex: 20
    },
    "& .react-datepicker__day, .react-datepicker__day-name, .react-datepicker__current-month": {
        fontFamily: "'Montserrat', sans-serif !important"
    },

    "& .dateInput": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        textDecoration: "none",
        backgroundColor: "white",
        height: "34px",
        padding: "0px 8px",
        minWidth: 140,
        width: 140,
        fontSize: 14,
        fontFamily: "'Montserrat', sans-serif !important"
    },
    "& .react-datepicker__close-icon": {
        top: '10%'
    },
    "& .react-datepicker__close-icon::after": {
        width: '18px',
        height: '22px',
        fontSize: '17px'
    },
    "@media (max-width: 1279px)": {
        "& .filter-bar": {
            display: 'none'
        },
    },
    "@media (max-width: 820px)": {
        "& .dateInput": {
            minWidth: 'auto',
            width: '100%'
        }
    }
})

const DatePickerContainer = styled(Box)({
    display: 'flex',
    gap: 20,
    "& .select-date-item": {
        position: 'relative',
        '& .title': {
            color: '#334155',
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "22px",
            marginBottom: 5,
        },
        "& .icon-calendar": {
            color: "#64748B",
            fontSize: 18,
        }
    }
})

const CustomTableContainer = styled(TableContainer)({
    boxShadow: "1px 2px 4px 0px #00000040",
    "& .table-body": {
        background: "white",
    },
    "& .table": {
        borderCollapse: 'separate',
    },
    "@media (max-width: 820px)": {
        "& .table-cell": {
            borderLeft: "10px solid"
        }
    },
    "@media (max-width: 1070px) and (min-width: 960px)": {
        "& .table-cell": {
            borderLeft: "10px solid"
        }
    }
})
const StatusContainer = styled(Box)(() => ({
    padding: "5px 10px",
    borderRadius: 40,
    textAlign: 'center',
    "& p": {
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "18px"
    },
    "& .small-text": {
        display: 'none'
    },
    "@media (max-width: 1070px) and (min-width: 960px)": {
        margin: "0 auto",
        width: 'fit-content',
        "& .small-text": {
            display: 'block'
        },
        "& .big-text": {
            display: 'none'
        },
    }
}))

const CustomPagination = styled(Pagination)({
    display: 'flex',
    justifyContent: 'end',
    marginTop: 20,
    "& .pagination-item": {
        fontFamily: "'Montserrat', sans-serif !important"
    }
})

const FilterContainer = styled(Box)({
   display: 'flex', 
   flexWrap: 'wrap',
   alignItems: 'center',
   margin: "20px auto",
   gap: 20
})

const FilterMainContent = styled(Box)({
    display: 'flex',
    gap: "10px 20px",
    overflowX: 'auto',
    padding: "10px 5px",
    alignItems: 'center',
})

const CustomSelect = styled(Select)({
    padding: 8,
    width: "fit-content",
    height: '40px',
    boxShadow: "1px 1px 10px 0px #00000040",
    borderRadius: 12,
    background: "#FFFFFF",
    "& .item-select": {
        display: "flex"
    },
})

const SelectItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: 5,
    padding: 5,
    "&:hover": {
        background: "#E5E5FF"
    }
})


const DateSortContainer = styled(ButtonBase)({
    padding: 8,
    width: 'fit-content',
    height: '40px',
    boxSizing: 'border-box',
    boxShadow: "1px 1px 10px 0px #00000040",
    borderRadius: 12,
    background: "#FFFFFF",
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    textWrap: 'nowrap',
    '& img': {
        height: 20
    }
})

const AutoCompleteContainer = styled(Box)({
    height: '40px',
})

const MyAutoComplete = styled(Autocomplete)({
    padding: "0 8px",
    boxShadow: "1px 1px 10px 0px #00000040",
    borderRadius: 12,
    background: "#FFFFFF",
    height: 40,
    width: 150,
    boxSizing: 'border-box',
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
}) as typeof Autocomplete

const CustomInput = styled(Input)({
    borderRadius: 12,
    backgroundColor: '#FFFFFF',
    height: 40,
    textTransform: 'none',
    border: 'none',
    outline: 'none',
    fontSize: 16,
    minWidth: 157,
    padding: 8,
    width: 157,
    boxShadow: "1px 1px 10px 0px #00000040",
})

const CustomButton = styled(Button)({
    borderRadius: 8,
    color: "#FFFFFF",
    height: 'fit-content',
    width: 'fit-content',
    padding: "10px 16px",
    '& p': {
        fontWeight: 700
    },
    '&.button-cancel': {
        background: '#E5E5FF',
        '& p': {
            color: "#152764"
        },
    },
    '&.button-remove-filter': {
        background: '#F87171',
        padding: 10
    },
    '&.button-filter': {
        background: '#34D399',
        padding: 10
    },
    '&.gradient': {
        background: "linear-gradient(99.09deg, #767BD7 2.64%, #111F50 100%)"
    },

    "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
        color: "#FFFFFF",
    },
})
const DialogEdit = styled(Dialog)({
    "& .icon-close": {
        position: 'absolute',
        right: "10px",
        cursor: "pointer"
    },
    "& .title": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 700,
        color: '#0F172A',
    },
    "& .select-label": {
        color: '#334155',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 10
    },
    "& .select-group": {
        width: "70%",
        margin: "20px 0",
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    "& .dialog-select": {
        width: "100%",
        border: "1px solid #CBD5E1",
        boxShadow: 'none',
        borderRadius: 8
    },
    "@media (max-width: 600px)": {
        "& .select-group": {
            width: "100%",
        }
    }
})
// Customizable Area End
