// Customizable Area Start
import React from "react";
import { Box, ThemeProvider, createTheme, Container, styled, Typography, Grid, Input, Select, MenuItem, Button, IconButton, Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import Header from "../../navigationmenu/src/Header.web";
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';


const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiMenu: {
            paper: {
                border: '1px solid #CBD5E1',
                boxShadow: 'none !important'
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "8px 8px 32px 8px"
            }
        },
        MuiSelect: {
            icon: {
                right: 10,
                color: '#0F172A'
            }
        },
        MuiDialogContent: {
            root: {
                position: "relative"
            }
        }
    }
})
import ConductAuditController, {
    Props
} from "./ConductAuditController";
import { configJSON } from "./TaskController";
import { icUpload } from "./assets";

// Customizable Area End

export default class ConductAudit extends ConductAuditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    NoAccessUI = () => {
        return <Typography style={{ marginTop: 30, width: "100%", textAlign: 'center'}}>{configJSON.noAccess}</Typography>
    }

    LoadingMask = () => {
        return <Dialog PaperProps={{style: {background: 'none', boxShadow: 'none'}}} fullWidth open={this.state.isLoadingSubmit}>
        <DialogContent dividers>
                <Box style={{ ...styles.dialogContent, marginTop: 20, marginBottom: 30 } as React.CSSProperties}>
                    <CircularProgress style={{ color: "#34D399", height: 50, width: 50 }} />
                    <Typography align="center" style={{ ...styles.successText, marginTop: 10, color: '#FFFFFF' }}>
                        {configJSON.isLoadingMessage}</Typography>
                </Box>
        </DialogContent>
    </Dialog>
    }

    DialogSuccess = () => {
        return <Dialog fullWidth open={this.state.openDialog} onClose={this.onCloseDialog}>
            <DialogContent dividers>
                <CloseIcon data-test-id="btn-close-dialog" onClick={this.onCloseDialog} style={styles.iconClose as React.CSSProperties} />
                <Box style={styles.dialogContent as React.CSSProperties}>
                    <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
                    <Typography align="center" style={{ ...styles.successText }}>
                        {this.state.status === 'assigned'?configJSON.submitInfoSuccess : configJSON.submitAuditSuccessMsg}</Typography>
                </Box>
            </DialogContent>
        </Dialog>
    }

    BeforeSubmitAuditUI = () => {
        const formatTime = (time: number) => {
            return time < 10 ? '0' + time : time.toString();
        }
        return <ContentBox style={{ overflowX: this.state.listImage.length === 0 ? 'hidden' : 'auto' }}>
            <TitleGroup>
                <Title className="title">{configJSON.conductAudit}:</Title>
            </TitleGroup>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={4} xs={12} className="row-title" style={styles.displayBetween}>
                    <TypographyContent>1. {configJSON.buildingPictures}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <CustomInput
                        data-test-id="input-pictures"
                        className="clickable"
                        placeholder={"Choose a file"}
                        fullWidth={true}
                        disableUnderline
                        readOnly
                        onClick={this.onClickUploadImage}
                        endAdornment={<img src={icUpload} alt="" style={{ marginRight: 5 }} />}
                    />
                    <input
                        ref={this.inputImgRef}
                        multiple
                        type="file"
                        id="file"
                        data-test-id="input-upload-img"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => this.onUploadImage(event.target.files)}
                        style={{ display: 'none' }} />
                </Grid>
                <Grid item className="space" lg={5} md={3} sm={2}></Grid>
                {this.state.listImage.length > 0 &&
                    <ImageContainer>
                        {this.state.listImage.map(image =>
                            <ImageBox key={image.id}>
                                <img src={image.source} alt="" style={styles.image as React.CSSProperties} />
                                <IconButton data-test-id="btn-remove-img" onClick={() => this.removeImageItem(image.id)} style={styles.iconClose as React.CSSProperties}>
                                    <CloseIcon style={{ color: '#0F172A' }} />
                                </IconButton>
                            </ImageBox>)}
                    </ImageContainer>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={4} xs={12} className="row-title" style={styles.displayBetween}>
                    <TypographyContent>2. {configJSON.inTime}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                        <CustomSelect
                        labelId="demo-customized-select-label"
                        data-test-id="select-time"
                        placeholder="Select time"
                        displayEmpty
                        MenuProps={{
                            disablePortal: true,
                            getContentAnchorEl: null,
                            anchorOrigin: { vertical: "bottom", horizontal: "right" },
                            transformOrigin: { vertical: 'top', horizontal: 'right' },
                            TransitionProps: { style: { marginTop: 15, borderRadius: 8 } }
                        }}
                        autoWidth={this.state.width > 500}
                        value={[this.state.inTime]}
                        SelectDisplayProps={{ style: { padding: 10 } }}
                        IconComponent={QueryBuilderRoundedIcon}
                        multiple
                        renderValue={(value: any) => { return value ? value : 'Select here' }}
                        input={<Input disableUnderline placeholder="Select here" />}
                    >
                                <SelectTime container>
                                    <Grid item xs={12} style={{ ...styles.timeSelectItem, height: 'auto', borderBottom: '1px solid #CBD5E1'}}>
                                        <TypographyContent>{this.state.inTime}</TypographyContent>
                                    </Grid>
                                    <Grid item xs={6} style={{ ...styles.timeSelectItem, borderRight: '1px solid #CBD5E1' }}>
                                        {[...Array(24).keys()].map((item) =>
                                            <TypographyContent
                                                data-test-id="input-in-hour"
                                                onClick={() => { this.onChangeTime('h', formatTime(item)) }}
                                                className={parseInt(this.state.time.hour) === item ? "select-item active" : "select-item" }
                                                key={item}>
                                                {formatTime(item)}
                                            </TypographyContent>)}
                                    </Grid>
                                    <Grid item xs={6} style={{ ...styles.timeSelectItem}}>
                                        {[...Array(60).keys()].map((item) =>
                                            <TypographyContent
                                                data-test-id="input-in-min"
                                                onClick={() => { this.onChangeTime('m', formatTime(item)) }}
                                                className={parseInt(this.state.time.min) === item ? "select-item active" : "select-item" }
                                                key={item}>
                                                {formatTime(item)}
                                            </TypographyContent>)}
                                    </Grid>
                                </SelectTime>
                    </CustomSelect>
                       
                    
                </Grid>
                <Grid item className="space" lg={5} md={3} sm={2}></Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={4} xs={12} className="row-title" style={styles.displayBetween}>
                    <TypographyContent>3. {configJSON.picName}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <CustomSelect
                        labelId="demo-customized-select-label"
                        data-test-id={'select-pic'}
                        placeholder="Select here"
                        displayEmpty
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: { vertical: "bottom", horizontal: "right" },
                            transformOrigin: { vertical: 'top', horizontal: 'right' },
                            TransitionProps: { style: { marginTop: 15, borderRadius: 8 } }
                        }}
                        SelectDisplayProps={{ style: { padding: 10 } }}
                        onChange={(event) => this.onChangePicName(event.target.value as string)}
                        value={this.state.picName}
                        IconComponent={ArrowDropDownRoundedIcon}
                        renderValue={(value: any) => { return value ? value : 'Select here' }}
                        input={<Input disableUnderline placeholder="Select here" />}
                    >
                        {this.state.listPicName.map((item, itemidx) =>
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        )}
                    </CustomSelect>
                </Grid>
                <Grid item className="space" lg={5} md={3} sm={2}></Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item className="space" lg={2} md={3} sm={4} xs={12}>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <MyButton data-test-id="btn-submit" disabled={this.disableButton()} onClick={this.handleSubmitAudit}>
                        <ListAltRoundedIcon className="icon-list" />
                        <Typography>{configJSON.filloutreport}</Typography>
                    </MyButton>
                </Grid>
            </Grid>
        </ContentBox>
    }

    AfterSubmitAuditUI = () => {
        return <ContentBox style={{ overflowX: this.state.listImage.length === 0 ? 'hidden' : 'auto' }}>
            <TitleGroup>
                <Title className="title">{configJSON.auditReportRules}</Title>
                <TypographyContent style={{ display: 'inherit', alignItems: 'center' }} component="span">
                    <PlaceOutlinedIcon /> Audit Location: {this.state.locationName}
                </TypographyContent>
            </TitleGroup>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={4} xs={12} className="row-title" style={styles.displayBetween}>
                    <TypographyContent>{configJSON.buildingPictures}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Grid>
                <Grid item lg={10} md={9} sm={8} xs={12}>
                    <ImageContainer>
                        {this.state.listImage.map(image =>
                            <ImageBox key={image.id}>
                                <img src={image.source} alt="" style={styles.image as React.CSSProperties} />
                            </ImageBox>)}
                    </ImageContainer>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={4} xs={5} style={styles.displayBetween}>
                    <TypographyContent>{configJSON.inTime}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={7}>
                    <TypographyContent>{this.state.inTime}</TypographyContent>
                </Grid>
                <Grid item className="space" lg={5} md={3} sm={2}></Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={4} xs={5} style={styles.displayBetween}>
                    <TypographyContent>{configJSON.outTime}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={7}>
                    <TypographyContent>{this.state.outTime}</TypographyContent>
                </Grid>
                <Grid item className="space" lg={5} md={3} sm={2}></Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={4} xs={5} style={styles.displayBetween}>
                    <TypographyContent>{configJSON.picName}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={7}>
                    <TypographyContent>{this.state.picName}</TypographyContent>
                </Grid>
                <Grid item className="space" lg={5} md={3} sm={2}></Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item className="space" lg={2} md={3} sm={4} xs={12}>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <MyButton className="gradient" data-test-id="btn-goto-report" onClick={this.gotoReport}>
                        <ListAltRoundedIcon className="icon-list" />
                        <Typography>{configJSON.filloutreport}</Typography>
                    </MyButton>
                    <MyButton className="gradient" data-test-id="btn-submit-to-review" onClick={this.handleSubmitAuditToReview}>
                        <Typography>{configJSON.submitAuditForReview}</Typography>
                    </MyButton>
                </Grid>
            </Grid>
        </ContentBox>
    }
    MainUI = () => {
        if(this.state.isLoading) return <CircularProgress style={{margin: 30}} />
        return this.state.formSubmitted ? this.AfterSubmitAuditUI() : this.BeforeSubmitAuditUI()

    }
    CheckPermissionUI = () => {
        const role = this.state.user.role.toLowerCase()
        return role === 'auditor' ? this.MainUI() : this.NoAccessUI()
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <Header navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    <CustomContainer maxWidth={false}>
                       {this.state.isRoleLoading ? <CircularProgress style={{margin: 30}} /> : this.CheckPermissionUI()}
                    </CustomContainer>
                    {this.DialogSuccess()}
                    {this.LoadingMask()}
                </ThemeProvider>
            </Header>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    gridContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    displayBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    image: {
        width: 121,
        height: 121,
        objectFit: 'cover'
    },
    iconClose: {
        border: '1px solid black',
        background: '#FFFFFF',
        position: 'absolute',
        top: 10,
        right: 10,
        padding: 3,
        borderRadius: 100,
        cursor: 'pointer'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 30
    },
    successText: {
        fontWeight: 700,
        lineHeight: "32px",
        color: "#0F172A",
        marginTop: 10,
        fontSize: 24,
    },
    timeSelectItem: {
        height: 200,
        width: 80,
        overflow: 'auto',
        textAlign: 'center',
        padding: 10,
    } as React.CSSProperties
}

const ImageBox = styled(Box)({
    boxShadow: "0px 4px 8px 0px #00000033",
    position: 'relative',
    width: 168,
    height: 160,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 8,
    minWidth: 168

})

const ImageContainer = styled(Box)({
    display: 'flex',
    gap: 20,
    overflowX: 'auto',
    padding: 20
})


const CustomSelect = styled(Select)({
    width: '100%',
    border: "1px solid #CBD5E1",
    borderRadius: 8,
    padding: "5px 0",


})

const SelectTime = styled(Grid)({
    '& .select-item': {
        cursor: 'pointer',
        padding: '5px 0',
        '&.active': {
            background: '#E5E5FF !important'
        },
        '&:hover': {
            background: '#f2f2fc'
        }
    }
})
const CustomInput = styled(Input)({
    border: "1px solid #CBD5E1",
    borderRadius: 8,
    padding: 10,
    '&.clickable': {
        cursor: 'pointer'
    }
})
const TypographyContent = styled(Typography)({
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: "'Montserrat', sans-serif !important"
}) as typeof Typography

const MyButton = styled(Button)({
    background: '#E5E5FF !important',
    padding: "12px 16px",
    borderRadius: 8,
    width: 'fit-content',
    '&.gradient': {
        background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%) !important",
        '& p': {
            color: '#ffffff'
        },
        '& .icon-list': {
            color: '#FFFFFF',
        }
    },
    '& .icon-list': {
        color: '#0F172A',
        marginRight: 5
    },
    "& p": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 16,
        color: "#0A122F",
        lineHeight: "24px",
        fontWeight: 700,

    },
    "&:disabled": {
        background: "#A9A9A9 !important",
        color: "#FFFFFF",
        cursor: "not-allowed",
        opacity: 0.5,
        '& p': {
            color: "#FFFFFF"
        }
    },
    "@media (max-width: 600px)": {
        alignSelf: 'center',
        "& p": {
            fontSize: 14
        }
    }
})

const TitleGroup = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    "@media (max-width: 650px)": {
        flexDirection: 'column',
        gap: 20
    }
})
const Title = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#0F172A',
    textTransform: 'capitalize'
})
const ContentBox = styled(Box)({
    width: "100%",
    display: "flex",
    gap: 20,
    position: 'relative',
    borderRadius: 8,
    flexDirection: "column",
    margin: "30px",
    padding: 30,
    border: '1px solid #CBD5E1',
    minHeight: 'calc(100vh - 205px)',
    height: 'fit-content',
    backgroundColor: '#FFFFFF',
    "& input[type=\"time\"]::-webkit-calendar-picker-indicator": {
        background: "none"
    },

    "@media (max-width: 600px)": {
        '& .space': {
            display: 'none'
        },
        margin: 20,
        '& .row-title': {
            justifyContent: 'start !important'
        }
    }
})
const CustomContainer = styled(Container)({
    minHeight: "calc(100vh - 80px)",
    height: "auto",
    position: 'relative',
    padding: 0,
    overflowX: 'hidden',
    display: 'flex'
})
// Customizable Area End
