// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

export interface QuestionImage { 
    id: string, 
    source: string
    sourceId: string | number | null
}

export type SectionType = 'critical' | 'non-critical' | 'safety-culture'
export type OptionType = 'IN' | 'OUT' | 'N/O' | 'COS' | ''
export interface Question {
    id: string | number,
    question: string,
    option: OptionType,
    comment: string,
    point: number | null,
    listImage: Array<QuestionImage>,
    listOption: Array<string>
}

export interface FormList {
    id: string | number,
    formSectionId: string | number,
    section: string,
    question: Array<Question>
}

interface S {
    // Customizable Area Start
    activeTab: string | SectionType,
    activeSubsection: string | number, 
    activeQuestion: string | number
    isOpenCamera: boolean
    isLoading: boolean,
    listTabs: Array<string>,
    customFormId: string | number,
    originalSubsections: Array<FormField>
    isOpenDialog: boolean
    auditId: string
    openZoomImage: boolean
    zoomImg: QuestionImage
    points: string
    isConductAudit: boolean
    auditStatus: string
    user: {
        id: string, 
        role: string, 
        group: 'evermore' | 'client' | 'regular'
    }
    isLoadingSubmit: boolean,
    sectionInfo: any,
    auditInfo: any
    callFormSubmitted: boolean
    isAllDataFilled: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start

export interface Field {
    id: number | string, 
    sub_section_id: number | string,
    field_type: string,
    field_options: string,
    field_value: string | null,
    field_sort_order: number,
    field_text?: string,
    points: number,
    images?: any,
    isLoadingImage?: boolean
}

export interface FormField {
    id: number,
    name: string,
    fields: Array<Field>,
}
// Customizable Area End

export default class ReportController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    saveQuestionCallId: string = ""
    imgRef: any
    getFormDataId: string = ""
    getListTabId : string = ""
    uploadImageCalId: string = ""
    saveFormDataId: string = ""
    deleteImageCallId: string = ""
    updateStatusCallId: string = ""
    cancelFormCallId: string = ""
    getAuditInfoCallId: string =""
    getUserRoleCallId: string = ""
    getFullFormDataId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        this.state = {
            activeTab: 'critical',
            activeQuestion: '',
            activeSubsection: '',
            isOpenCamera: false,
            isLoading: false,
            listTabs: [],
            customFormId: '',
            originalSubsections: [],
            isOpenDialog: false,
            auditId: "",
            openZoomImage: false,
            zoomImg: {
                id: '', 
                source: '', 
                sourceId: null
            },
            isConductAudit: false,
            points: '---/---',
            auditStatus: "", 
            user: {
                id: "", 
                role: "", 
                group: 'regular'
            },
            isLoadingSubmit: false,
            sectionInfo: {},
            auditInfo: {},
            callFormSubmitted: false,
            isAllDataFilled: false
        };
        this.imgRef = React.createRef()
        // Customizable Area End
    }
    // Customizable Area Start

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(responseJson.errors) {
                this.handleResponseError(responseJson.errors)
            } else {
                this.handleSuccessResponse(apiRequestCallId, responseJson)
            }
        }
    }

    handleSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.saveQuestionCallId) {
            this.handleSaveQuestionResponse(responseJson)
         }

         if (apiRequestCallId === this.getFormDataId) {
             this.handleGetFormResponse(responseJson)
         }

         if (apiRequestCallId === this.getListTabId) {
            this.handleGetListTabResponse(responseJson)
         }

         if (apiRequestCallId === this.getFullFormDataId) {
            const isAllDataFilled = this.handleGetFullFormDataResponse(responseJson)
            this.setState({isAllDataFilled})
         }

         if (apiRequestCallId === this.saveFormDataId) {
             this.handleSaveFormResponse(responseJson)
         }

         if(apiRequestCallId === this.uploadImageCalId) {
             this.handleUploadImgResponse(responseJson)
         }

         if(apiRequestCallId === this.cancelFormCallId){
             this.setState({points: '---/---'})
             this.getFormData(this.state.activeTab)
         } 

         if(apiRequestCallId === this.getAuditInfoCallId) {
             this.handleStatusResponse(responseJson)
         }
         this.handleUserRoleResponse(apiRequestCallId, responseJson)
    }

    handleSaveQuestionResponse = (responseJson: any) => {
        if(responseJson.data) {
            const section = responseJson.data.sections.find((section: any) => section.name === this.state.activeTab)
            this.setState({points: section?.points_given+'/'+ section?.points_applicable})
        }
    }

    handleUserRoleResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getUserRoleCallId) {
            if (responseJson.meta) {
                const roleData  = responseJson.meta
                this.setState({ user: { ...roleData, group: this.getUserGroup(roleData.role) }})
            }
        }
    }

    getUserGroup = (role: string) => {
        const evermoreUserGroup = ['All Access User', 'Auditor', 'Account Manager', 'Team Leader']
        const clientUserGroup = ['Super User', 'Regional Manager', 'Area Manager', 'General Manager']
        if(evermoreUserGroup.includes(role)) return 'evermore'
        if(clientUserGroup.includes(role)) return 'client'
        return 'regular'
    }

    handleStatusResponse = (responseJson: any) => {
        if(responseJson.data){
            this.setState({auditStatus: responseJson.data.attributes.status, auditInfo: responseJson.data}, () => {
                this.getListTabs()
            })
        }
    }

    handleUploadImgResponse = (responseJson: any) => {
        if(responseJson.data) {
            this.handleSuccessUploadImage(responseJson.data.attributes.images)
        }
    }

    handleGetFormResponse = (responseJson: any) => {
        if (responseJson.data) {
            const section = responseJson.data.sections[0]
            this.setState({
                customFormId: responseJson.data.custom_form_id,
                points: section?.points_given + '/' + section?.points_applicable,
                sectionInfo: {
                    id: section.id, 
                    name: section.name,
                    points_given: section.points_given,
                    point_applicable: section.points_applicable
                }
            })
            this.handleFormatFormData(section?.subsections)
        } 
    }

    handleGetListTabResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.handleListTabData(responseJson.data.sections)
        } 
    }

    handleSaveFormResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({isOpenDialog: true, isLoadingSubmit: false, callFormSubmitted: false})
        } 
    }

    async componentDidMount(){

        const isConductAudit = await getStorageData("isConductAudit");
        this.setState({isConductAudit});
        await removeStorageData("isConductAudit")
        await this.getUserRole()
        await this.getAuditId()
        await this.getAuditInfo()
        
    }

    getAuditId = async () => {
        const id = await this.props.navigation.getParam("id");
        this.setState({ auditId: id })
    }

    goToLogin = async () => {
        const auditId = await this.props.navigation.getParam('id')
        await setStorageData("name", "CriticalForm")
        await setStorageData("id", auditId)
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    onToggleDialog = () => {
        this.setState({isOpenDialog: !this.state.isOpenDialog})
        this.getListTabs()
        const {isAllDataFilled} = this.state
        if(!isAllDataFilled) return;
        if(this.state.isConductAudit) this.props.navigation.navigate("AuditReportRules", {id: this.state.auditId})
        else {
            this.props.navigation.navigate("AuditInfo",{id: this.state.auditId})
        }
    }

    handleResponseError = (errors: Array<any>) => {
        const listErr: {[key: string] : string} = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            listErr[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if(listErr['token']) this.goToLogin()
    }

    onChangeTab = (tab: string) => {
        this.setState({ activeTab: tab, points: '---/---' })
        this.getFormData(tab)
    }

    openZoomImage = (imgId: string, questionId: string | number, id: string | number) => {
        const {originalSubsections} = this.state;
        const {index1, index2} = this.findChangeItem(id, questionId)
        const {images} = originalSubsections[index1].fields[index2]
        const img = images.find((img: QuestionImage) => img.id === imgId) as QuestionImage;
        this.setState({originalSubsections: [...originalSubsections], openZoomImage: true, zoomImg: img})
    }
    closeZoomImage = () => {
        this.setState({openZoomImage: false})
    }
    isActiveTab = (currentTab: string) => {
        return this.state.activeTab === currentTab ? 'active' : ''
    }
    findChangeItem = (id: string | number, questionId: string | number) => {
        const {originalSubsections} = this.state;
        const item = originalSubsections.find(item => item.id === id) as FormField;
        const question = item?.fields.find(ques => ques.id === questionId ) as Field;
        const index1 = originalSubsections.indexOf(item)
        const index2 = item.fields.indexOf(question);

        return {index1, index2}
    }
    onChangeOption = (id: string | number, questionId: string | number, option: OptionType) => {
        const {originalSubsections} = this.state;
        const {index1, index2} = this.findChangeItem(id, questionId)
        originalSubsections[index1].fields[index2].field_value = option
        this.setState({originalSubsections: [...originalSubsections]})
        this.onSaveNotes()
    }

    onChangeNotes = (id: string | number, questionId: string | number, comment: string) => {
        const {originalSubsections} = this.state;
        const {index1, index2} = this.findChangeItem(id, questionId)
        originalSubsections[index1].fields[index2].field_text = comment
        this.setState({originalSubsections: [...originalSubsections]})
    }

    onClickUploadPhoto = (id: string | number, questionId: string | number) => {
        this.imgRef.current.click()
        this.setState({activeQuestion: questionId, activeSubsection: id})
    }

    toDataURL = async (url: string) => {
        const result = await fetch(url)
        const blob = await result.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        })

            }

    onChangeFile = async ( files: any) => {
        const {activeSubsection} = this.state
        const questionId = this.state.activeQuestion
        const {originalSubsections: subsection} = this.state;
        const {index1: idx1, index2: idx2} = this.findChangeItem(activeSubsection, questionId)
        const imageFormData = new FormData();
        imageFormData.append('custom_form_id', this.state.customFormId as string);
        imageFormData.append('field_id', questionId as string);

        subsection[idx1].fields[idx2].isLoadingImage = true
        this.setState({originalSubsections: [...subsection]})

        for (let imgItem of subsection[idx1].fields[idx2].images) {
            await this.toDataURL(imgItem.source).then((dataUrl: any) =>{
                imageFormData.append("images[]", this.dataURLtoBlob(dataUrl))
            })
        }

        for(let i of files) {
            imageFormData.append("images[]", i)
        }

        this.uploadImageCalId = await this.apiCall({
            body: imageFormData,
            endpoint: `${configJSON.uploadImageEndpoint}/${this.state.customFormId}/upload_images`,
            method: configJSON.methodPost,
        })  
    }

    removeImageItem = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        imgId: string, activeSubsection: string | number, activeQuestion: string | number) => {
        event.stopPropagation()
        const {originalSubsections} = this.state;
        const { index1, index2 } = this.findChangeItem(activeSubsection, activeQuestion)
        const { images: listImage } = originalSubsections[index1].fields[index2]
        const img = listImage.find((img: QuestionImage) => img.id === imgId) as QuestionImage;
        const indexOfImg = listImage.indexOf(img)
        listImage.splice(indexOfImg, 1)

        this.deleteImageCallId = await this.apiCall({
            body: {},
            endpoint: `${configJSON.uploadImageEndpoint}/${this.state.customFormId}/destroy_image?field_id=${activeQuestion}&image_id=${img.sourceId}`,
            method: configJSON.methodDelete,
        })
        this.setState({ originalSubsections: [...originalSubsections] })
    }

    handleSuccessUploadImage = (listImage: any) => {
        const id = this.state.activeSubsection
        const questionId = this.state.activeQuestion
        const {originalSubsections} = this.state;
        const {index1, index2} = this.findChangeItem(id, questionId)
        const listImgLength = listImage.length
        originalSubsections[index1].fields[index2].isLoadingImage = false
        originalSubsections[index1].fields[index2].images = [];

        for (let i = 0; i < listImgLength; i ++ ) {
            let item = listImage[i];
            item.sourceId = listImage[i].id
            originalSubsections[index1].fields[index2].images.push({
                id: item.id,
                source: item.url,
                sourceId: item.id
            })
        }

        this.setState({originalSubsections: [...originalSubsections]})

    }

    apiCall = async (params: {body?: {}, endpoint: string, method: string, contentType?: string}) => {
        const token = await getStorageData('authToken')
        const {body, endpoint, method, contentType} = params;

        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": contentType,
                token
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        body && reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );

        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    formatFormDataToSave = () => {
        const {originalSubsections, sectionInfo} = this.state

        return {
            data: {
                attributes: {
                    sections: [{
                        id: sectionInfo.id,
                        name: this.state.activeTab,
                        subsections: originalSubsections
                    }]
                }
            },
        };
    }

    onSaveNotes = async () => {
        const body = this.formatFormDataToSave()

        this.saveQuestionCallId = await this.apiCall({
            body: JSON.stringify(body), 
            method: configJSON.methodPut, 
            endpoint: `${configJSON.saveFormDataEndpoint}/${this.state.customFormId}`,
            contentType: configJSON.getUserListApiContentType
        })
    }

    getAuditInfo = async () => {
        this.getAuditInfoCallId = await this.apiCall({
            endpoint: `${configJSON.getStatusTrackerEndpoint}/${this.state.auditId}`,
            method: configJSON.getUserListApiMethod,
            contentType: configJSON.getUserListApiContentType,
        })
    }

    getUserRole = async () => {
        this.getUserRoleCallId = await this.apiCall({
            endpoint: configJSON.getUserRoleEndpoint,
            method: configJSON.getUserListApiMethod,
            contentType: configJSON.getUserListApiContentType,
        })
      }

    handleCancelForm = async () => {
        const body = {
            id: this.state.customFormId
        }
        this.cancelFormCallId = await this.apiCall({
            body: JSON.stringify(body),
            endpoint: `${configJSON.cancelFormEndpoint}/${this.state.customFormId}/clear`, 
            method: configJSON.methodPost
        })
    }

    getFullFormData = async () => {
        const formName = this.state.auditInfo?.attributes?.form_name ?? "Food Inspection Form"

        this.getFullFormDataId = await this.apiCall({
            method: 'GET', 
            endpoint: configJSON.getAllFormEndpoint + `?form_name=${formName}&audit_id=${this.state.auditId}`,
            contentType: 'application/json'
        })
    }

    handleSaveForm = async () => {
        const body = this.formatFormDataToSave()
        const formData = new FormData()
        const subsection = body.data.attributes.sections[0].subsections
        this.getFullFormData()

        for(let subsectionItem of subsection) {
            for (let fieldItem of subsectionItem.fields) {
                if(!fieldItem.field_value) {
                    this.setState({callFormSubmitted: true})
                    return;
                }
            }
        }
        this.setState({isLoadingSubmit: true})

        if (this.state.isConductAudit) {
            const date = new Date()
            const time = date.getHours() + ":" + date.getMinutes()
            formData.append("audit[out_time]", time)
            this.updateStatusCallId = await this.apiCall({
                body: formData,
                endpoint: `${configJSON.taskAuditEndpoint}/${this.state.auditId}/conduct_audit`,
                method: configJSON.methodPut,
            })
        }
        this.saveFormDataId = await this.apiCall({
            body: JSON.stringify(body), 
            endpoint: `${configJSON.saveFormDataEndpoint}/${this.state.customFormId}`,
            method: configJSON.methodPut, 
            contentType: configJSON.getUserListApiContentType
        })
    }

    handleTakePhoto = async (dataUri: any) => {
        this.setState({ isOpenCamera: false })
        const id = this.state.activeSubsection
        const questionId = this.state.activeQuestion
        const {originalSubsections} = this.state
        const { index1, index2 } = this.findChangeItem(id, questionId)

        const formdata = new FormData();
        formdata.append('field_id', questionId as string);
        formdata.append('custom_form_id', this.state.customFormId as string);
        originalSubsections[index1].fields[index2].isLoadingImage = true
        this.setState({originalSubsections: [...originalSubsections]})

        for (let item of originalSubsections[index1].fields[index2].images) {
            await this.toDataURL(item.source).then((dataUrl: any) =>{
                 formdata.append("images[]", this.dataURLtoBlob(dataUrl))
            })  
        }
        formdata.append("images[]", this.dataURLtoBlob(dataUri))

        this.uploadImageCalId = await this.apiCall({
            body: formdata,
            endpoint: `${configJSON.uploadImageEndpoint}/${this.state.customFormId}/upload_images`,
            method: configJSON.methodPost,
        })  
    }

    dataURLtoBlob = (dataurl: any) => {
        const mimeTotal = dataurl.split(';')[0]
        const mime = mimeTotal.split(':')
        let arr = dataurl.split(','), bstr = atob(arr[1]), number = bstr.length, u8arr = new Uint8Array(number);
        while(number--){
            u8arr[number] = bstr.charCodeAt(number);
        }

        return new Blob([u8arr], {type: mime});
    }
    handleOpenCamera = (id: string | number, questionId: string | number) => {
        this.setState({isOpenCamera: true, activeQuestion: questionId, activeSubsection: id})
    }

    handleListTabData = (sections: Array<any>) => {
        const listTabs = [];
        for(let i in sections) {
            listTabs.push(sections[i].name)
        }
        this.getFormData(listTabs[0])
        this.setState({listTabs: [...listTabs], activeTab: listTabs[0]})
    }

    handleGetFullFormDataResponse = (responseJson: any) => {
        const sections = responseJson.data?.sections
        if (sections) {
            for (let sectionItem of sections) {
                const subsection = sectionItem.subsections

                for (let subsectionItem of subsection) {
                    for (let fieldItem of subsectionItem.fields) {
                        if (!fieldItem.field_value) {
                           return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    handleFormatFormData = (subsectionData: Array<FormField>) => {
        const subsections: Array<FormField> = []
        for(let i in subsectionData) {
            const item = subsectionData[i]
            const question = []
            for (let j in item.fields) {
                const itemj = item.fields[j]
                const listImage = [] as QuestionImage[]
                itemj.images?.forEach((itemImg: {id: string, url: string}) => {
                    listImage.push( {
                        id: itemImg.id,
                        source: itemImg.url,
                        sourceId: itemImg.id
                    })
                })
                question.push({
                    ...itemj,
                    images: listImage,
                })
            }
            subsections.push({
                id: item.id,
                name: item.name,
                fields: question
            })
        }
        
        this.setState({originalSubsections: [...subsections], isLoading: false})
    }

    onCloseCamera = () => {
        this.setState({ isOpenCamera: false })
    }


    getListTabs = async () => {
        this.setState({isLoading: true})
        const formName = this.state.auditInfo?.attributes?.form_name ?? "Food Inspection Form"

        this.getListTabId = await this.apiCall({
            method: 'GET', 
            endpoint: configJSON.getAllFormEndpoint + `?form_name=${formName}&audit_id=${this.state.auditId}`,
            contentType: 'application/json'
        })
    }

    getFormData = async (activeTab: string) => {
        this.setState({isLoading: true})
        const formName = this.state.auditInfo?.attributes?.form_name ?? "Food Inspection Form"

        this.getFormDataId = await this.apiCall({
            method: 'GET', 
            endpoint: configJSON.getSectionFormEndpoint + `?form_name=${formName}&audit_id=${this.state.auditId}&form_section=${encodeURIComponent(activeTab)}`,
            contentType: 'application/json'
        })
    }
    // Customizable Area End
}
// Customizable Area End
