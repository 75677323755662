Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.exportDetailReport = "Export detailed report"
exports.from = "From"
exports.to = "To"
exports.auditNumber = "Audit Number"
exports.accountName = "Account Name"
exports.status = "Status"
exports.assignedTo = "Assigned To"
exports.dateCreated = "Date Created"
exports.dateCompleted = "Date Completed"
exports.formType = "Form Name"
exports.auditorName = "Auditor Name"
exports.typeOfAudit = "Type of Audit"
exports.regionName = "Region Name"
exports.locationName = "Location Name"
exports.departmentName = "Department Name"
exports.methodGet = "GET"
exports.getReportingEndpoint = "bx_block_tasks/reporting_and_analytics"
exports.getFilterDataEndpoint = "bx_block_tasks/audits/get_names"
exports.cancel = "Cancel"
exports.filter = "Filter"
exports.noRecord = "No record found."
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.filterAccountEndpoint = "account_block/accounts/filtered_accounts"
exports.getFormNameEndpoint = "bx_block_custom_forms/index"

exports.getListDepartmentEndpoint = "account_block/accounts/fetch_location_departments"
exports.getListLocationEndpoint = "account_block/accounts/fetch_region_locations"
exports.getUserRegionEndpoint = "account_block/accounts/fetch_user_regions"
exports.getAccountNameEndpoint = "account_block/accounts/fetch_users"
// Customizable Area End