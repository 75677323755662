// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export type Tabtype = 'status-tracker' | 'audit-info' | 'cap' | ''
export interface Props {
    id: string
    navigation: any;
}

interface S {
    activeTab: Tabtype
    auditId: string,
    statusData: {audit: string, corrective_action_plan: string}
    isLoading: boolean
    userRole: {id: string, role: string, group: string},
    isPageLoading: boolean
}

interface SS {
    navigation: any;
}

export default class StatusTrackerController extends BlockComponent<
    Props,
    S,
    SS
> {
    getStatusTrackerCallId: string = ""
    getUserRoleCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



        this.state = {
            activeTab: 'status-tracker',
            auditId: '',
            statusData: {
                audit: "", 
                corrective_action_plan: ""
            },
            isLoading: true,
            userRole: {
                id: '',
                role: '',
                group: ""
              },
            isPageLoading: true
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserRole()
        
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
            if(apiCallId === this.getUserRoleCallId) {
                if(responseJson.meta) {
                   this.handleRoleResponse(responseJson.meta)
                } else if(responseJson.errors) {
                    this.handleError(responseJson.errors)
                }
            }
            if (apiCallId === this.getStatusTrackerCallId) {
               this.handleStatusTrackerResponse(responseJson)
            }     
        }
    }

    handleStatusTrackerResponse = (responseJson: any) => {
        if (responseJson.statuses) {
            const statusData = {
                audit: responseJson.statuses.audit,
                corrective_action_plan: responseJson.statuses.corrective_action_plans? responseJson.statuses.corrective_action_plans[0].status : ""
            }
            this.setState({
                statusData: { ...statusData },
                isLoading: false
            })
        } else if(responseJson.errors) {
            this.handleError(responseJson.errors)
        }
    }

    handleError = (errors: Array<object>) => {
        const list: { [key: string]: string } = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            list[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if (list['token']) this.goLogin()
    }

    goLogin = async () => {
        const auditId = await this.props.navigation.getParam('id')
        await setStorageData("name", "StatusTracker")
        await setStorageData("id", auditId)
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    handleRoleResponse = async (roleData: {id: string, role: string}) => {
        const auditId = await this.props.navigation.getParam('id')
        this.setState({userRole: {...roleData, group: this.getUserGroup(roleData.role)}, auditId, isPageLoading: false}, () => {
            if(this.state.userRole.group === 'evermore') this.getStatusTracker()
        })
    }


    isActiveTab = (currentTab: string) => {
        return this.state.activeTab === currentTab ? 'active' : ''
    }

    getUserGroup = (role: string) => {
        const evermoreUserGroup = ['All Access User', 'Auditor', 'Account Manager', 'Team Leader']
        if(evermoreUserGroup.includes(role)) return 'evermore'
        return 'client'
    }

    apiCall = async (params: {endpoint: string, method: string}) => {
        const token = await getStorageData("authToken");
        const {endpoint, method} = params
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": 'application/json',
                token
            })
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    getStatusTracker = async () => {
        this.getStatusTrackerCallId = await this.apiCall({
            endpoint: `${configJSON.getStatusTrackerEndpoint}/${this.state.auditId}/get_statuses`,
            method: configJSON.getApiMethod
        })
    }

    getUserRole = async () => {
        this.getUserRoleCallId = await this.apiCall({
            endpoint: configJSON.getUserRoleEndpoint,
            method: configJSON.getApiMethod
        })
      }

    onChangeTab = (tab: Tabtype) => {
       if(tab === 'cap') this.props.navigation.navigate('CAP', {id: this.state.auditId})
       if(tab === 'status-tracker') this.props.navigation.navigate('StatusTracker', {id: this.state.auditId})
       if(tab === 'audit-info') this.props.navigation.navigate('AuditInfo', {id: this.state.auditId})
    }
}
// Customizable Area End
