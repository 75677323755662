// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export type Tabtype = 'status-tracker' | 'audit-info' | 'cap' | '';
export interface CapType {
    id: number,
    question: string,
    notes: string,
    action: string,
    person_responsible: string
}
export interface Props {
    id: string
    navigation: any;
}

interface S {
    activeTab: Tabtype
    listCap: Array<CapType>
    auditId: string
    isOpenDialogSuccess: boolean
    isLoading: boolean
    user: {id: string, role: string, group: string}
    auditStatus: string
}

interface SS {
    navigation: any;
}

export const userEvermore = 'evermore'
export const userClient = 'client'

export default class CorrectiveActionPlanController extends BlockComponent<
    Props,
    S,
    SS
> {

    saveFormCallId: string = ""
    saveWholeFormCallId: string = ""
    getFormCallId: string = ""
    getUserRoleId: string = ""
    getAuditStatusId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



        this.state = {
            activeTab: 'cap',
            auditId: "",
            isOpenDialogSuccess: false,
            listCap: [],
            isLoading: true,
            user: {
                id: "",
                role: "",
                group: "regular"
            }, 
            auditStatus: ""
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserRole()
    }

    getAuditId = async (group: string) => {
        const id = await this.props.navigation.getParam("id");
        this.setState({ auditId: id }, () => {
            this.getFormData();
            if (group === "evermore")
                this.getAuditStatus()
        })
    }

    getUserRole = async () => {
        this.getUserRoleId = await this.apiCall({
            method: configJSON.getApiMethod,
            endpoint: configJSON.getUserRoleEndpoint
        })
      }
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(responseJson.errors) {
                this.handleErrors(responseJson.errors)
            } else {
               this.handleAllResponse(apiCallId, responseJson)
            }
            
        } 
    }

    handleAllResponse = (apiCallId: string, responseJson: any) => {
        if (apiCallId === this.getAuditStatusId) {
                this.setState({
                    auditStatus: responseJson.statuses.audit,
                })
        }    
        if (apiCallId === this.saveWholeFormCallId) {
            this.handleSaveWholeFormResponse(responseJson)
        }
        if(apiCallId === this.saveFormCallId) {
           this.handleSaveFormAuthorError(responseJson)
        }
        if (apiCallId === this.getFormCallId) {
           this.handleFormData(responseJson)
        }
        if(apiCallId === this.getUserRoleId){
            this.handleRoleData(responseJson.meta)
        }
    }

    handleRoleData = async (roleData: {id: string, role: string}) => {
        if(roleData) {
            const role = roleData.role?.toLowerCase()
            const clientUserGroup = ['super user', 'regional manager', 'area manager', 'general manager']
            let group = "regular"
            if(configJSON.evermore.includes(role)) group = userEvermore
            if(clientUserGroup.includes(role)) group = userClient
    
            if(group!== 'regular') await this.getAuditId(group)
            this.setState({user: {...roleData, group}})
        }
    }

    handleFormData = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ listCap: responseJson.data, isLoading: false })
        } 
    }

    handleSaveWholeFormResponse = (responseJson: any) => {
        if (responseJson.message) {
            this.setState({ isOpenDialogSuccess: true })
        }
    }

    handleSaveFormAuthorError = (responseJson: any) => {
        if (responseJson.error) {
            alert(responseJson.error)
        }
    }

    handleErrors = (errors: any) => {
        const listErr: { [key: string]: string } = {};
        errors.forEach((error: Object) => {
            listErr[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if (listErr['token']) {
            removeStorageData("authToken")
            this.goLogin()
        }
    }

    goLogin = async () => {
        const auditId = await this.props.navigation.getParam('id')
        await setStorageData("name", "CAP")
        await setStorageData("id", auditId)
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    onChangeTab = (tab: Tabtype) => {
        if (tab === 'cap') this.props.navigation.navigate('CAP', {id: this.state.auditId})
        if (tab === 'audit-info') this.props.navigation.navigate('AuditInfo', {id: this.state.auditId})
        if (tab === 'status-tracker') this.props.navigation.navigate('StatusTracker', {id: this.state.auditId})
    }

    onChangeInputValue = (capId: number, key: 'notes' | 'action' | 'person_responsible', value: string) => {
        const { listCap } = this.state;
        const capItem = listCap.find(item => item.id === capId) as CapType;
        capItem[key] = value;
        this.setState({ listCap: [...listCap] })
    }

    isActiveTab = (currentTab: string) => {
        return this.state.activeTab === currentTab ? 'active' : ''
    }

    onCloseDialog = () => {
        this.setState({ isOpenDialogSuccess: false })
    }

    apiCall = async (params: { body?: object, endpoint: string, method: string }) => {
        const token = await getStorageData('authToken')
        const { body, endpoint, method } = params
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": 'application/json',
                token
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }
    onSaveForm = async (targetValue: string) => {
        const {group} = this.state.user
        if (targetValue === "" || group === userEvermore) {
            return;
        }
        
        const dataList = []
        for (let i in this.state.listCap) {
            const item = this.state.listCap[i]
            dataList.push({
                id: item.id,
                question: item.question,
                notes: item.notes,
                action: item.action,
                person_responsible: item.person_responsible ?? "",
                audit_id: parseInt(this.state.auditId)
            })
        }
        const body = {
            corrective_action_plans: dataList
        }
        this.saveFormCallId = await this.apiCall({
            body,
            method: configJSON.patchApiMethod,
            endpoint: `${configJSON.saveCapEndpoint}/${this.state.auditId}`
        })
    }
    handleSaveAllForm = async () => {
        const {group} = this.state.user
        if(group === userEvermore) return;
        const dataList = []
        for (let i in this.state.listCap) {
            const item = this.state.listCap[i]
            dataList.push({
                id: item.id,
                question: item.question,
                action: item.action,
                person_responsible: item.person_responsible ?? "",
                notes: item.notes,
                audit_id: parseInt(this.state.auditId)
            })
        }
        const body = {
            corrective_action_plans: dataList
        }
        this.saveWholeFormCallId = await this.apiCall({
            body,
            method: configJSON.patchApiMethod,
            endpoint: `${configJSON.saveCapEndpoint}/${this.state.auditId}`
        })
    }
    getFormData = async () => {
        this.getFormCallId = await this.apiCall({
            method: configJSON.getApiMethod,
            endpoint: `${configJSON.getCapEndpoint}/${this.state.auditId}/show`
        })
    }

    getAuditStatus = async () => {
        this.getAuditStatusId = await this.apiCall({
            endpoint: `${configJSON.getStatusTrackerEndpoint}/${this.state.auditId}/get_statuses`,
            method: configJSON.getApiMethod
        })
    }
}
// Customizable Area End
