// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { icSort, icSortAsc, icSortDesc } from "./assets";

export const configJSON = require("./config");
export type Tabtype = 'status-tracker' | 'audit-info' | 'cap' | ''
const statusMapping = {'Assigned': 'assigned', 'Completed': 'completed', 'Under Review': 'under_review'}
interface AuditorType {
    id: string 
    full_name: string
    email: string
    attributes?: {
        full_name: string, 
        email: string
    }
}
const sampleEdittedRecord = {
    attributes :{
        audit_number: -1,
        account_name: "",
        status: "",
        assigned_to: "",
        date_created: "",
        date_completed: "",
        auditor_id: ""
      },
      auditor: {
        id: "",
        full_name: "",
        email: ""
      },
      id: "",
      type: ""
}
interface AuditDetails  {
    attributes :{
      audit_number: number,
      account_name: string,
      status: string,
      assigned_to: string,
      date_created: string,
      date_completed: string,
      auditor_id: string
    },
    auditor: AuditorType,
    id:string,
    type:string,
}

export interface Props {
    id: string
    navigation: any;
}

interface S {
    isLoading: boolean
    userRole: { id: string, role: string, group: string },
    isPageLoading: boolean
    fromDate: Date | null
    toDate: Date | null
    tableData: Array<any>
    width: number
    currentPage: number
    totalPage: number
    selectedAuditNumber: string
    listStatus: ['Assigned', 'Under Review', 'Completed']
    selectedStatus: Array<StatusType>
    listAccountName: Array<any>
    selectedAccountName: any
    listAuditor: Array<string>
    selectedAuditor: any
    isDateCreatedAsc: boolean | null
    isDateCompletedAsc: boolean | null
    isOpenModal: boolean
    edittedRecord: AuditDetails
    isOpenNotiToast: boolean
    error: boolean,
    notiMessage: string
    searchKey: string,
    hasCap: boolean | null
}

type StatusType = 'Assigned' | 'Under Review' | 'Completed'

interface SS {
    navigation: any;
}

export default class AuditListController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAuditListCallId: string = ""
    getUserRoleCallId: string = ""
    getAuditNumberId: string = ""
    getAccountNameId: string = ""
    getAuditorId: string = ""
    updateRecordId: string = ""
    deleteRecordId: string = ""
    auditNumberTimeout: ReturnType<typeof setTimeout>
    accountNameTimeout: ReturnType<typeof setTimeout>
    auditorTimeout: ReturnType<typeof setTimeout>
    statusTimeout: ReturnType<typeof setTimeout>
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



        this.state = {
            isLoading: true,
            userRole: {
                id: '',
                role: '',
                group: ""
            },
            isPageLoading: true,
            fromDate: null,
            toDate: null,
            tableData: [],
            width: window.innerWidth,
            currentPage: 1,
            totalPage: 1,
            selectedAuditNumber: '',
            listStatus: ['Assigned', 'Under Review', 'Completed'],
            selectedStatus: [],
            listAccountName: [],
            selectedAccountName: {},
            listAuditor: [],
            selectedAuditor: {},
            isDateCreatedAsc: null,
            isDateCompletedAsc: null,
            isOpenModal: false,
            edittedRecord: sampleEdittedRecord,
            isOpenNotiToast: false,
            error: false,
            notiMessage: "",
            searchKey: "",
            hasCap: null
        }
        this.auditNumberTimeout = setTimeout(() => {}, 1000)
        this.accountNameTimeout = setTimeout(() => {}, 1000)
        this.auditorTimeout = setTimeout(() => {}, 1000)
        this.statusTimeout = setTimeout(() => {}, 1000)

    }

    async componentDidMount() {
        super.componentDidMount();
        const hasCap = await getStorageData("hasCaps");
        this.setState({hasCap})
        removeStorageData("hasCaps")

        await this.getUserRole()
        window.addEventListener("resize", () => {
            this.setState({width: window.innerWidth})
        })
    }

    async componentWillUnmount(){
        window.removeEventListener("resize", () => {this.setState({width: window.innerWidth})})
      }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const searchKey = this.getSearchKey()
        if((searchKey !== this.state.searchKey) && searchKey && searchKey !== "") {
            this.setState({searchKey, currentPage: 1})
            await this.searchAudit(searchKey, 1)
        } else if ((searchKey !== this.state.searchKey) && !searchKey && this.state.searchKey !== "") {
            this.setState({searchKey: ""})
            await this.getAuditList()
        }
    }

    getSearchKey = () => {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const searchKey = urlSearchParams.get('search') || '';
        return searchKey
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson?.errors) {
                this.handleError(responseJson.errors)
            } else {
                this.handleRequestResponse(apiCallId, responseJson)
            }
        }
    }

    handleRequestResponse = (apiCallId: string, responseJson: any) => {
        if (apiCallId === this.getUserRoleCallId) {
            this.handleRoleResponse(responseJson.meta)
        }
        if (apiCallId === this.getAuditListCallId) {
           this.handleAuditListResponse(responseJson)
        }
        if (apiCallId === this.updateRecordId) {
            this.handleUpdateRecordResponse(responseJson, 'Updated')
        }
        if (apiCallId === this.deleteRecordId) {
            this.handleUpdateRecordResponse(responseJson, 'Deleted')
        }
        this.handleFilterResponse(apiCallId, responseJson)
    }

    handleUpdateRecordResponse = (responseJson: any, type: 'Updated' | 'Deleted') => {
        if(responseJson.data || responseJson.message){
            this.filterData(this.state.currentPage);
            this.setState({isOpenNotiToast: true, notiMessage: `Audit #${this.state.edittedRecord.id} ${type} Successfully`}, 
            () => {
                setTimeout(()=> {this.setState({isOpenNotiToast: false})}, 2000)
            })
        } else {
            this.setState({isOpenNotiToast: true, error: true, notiMessage: `Error While ${type} Audit #${this.state.edittedRecord.id}`}, 
            () => {
                setTimeout(()=> {
                    this.setState({isOpenNotiToast: false}, () => {this.setState({error: false})})
                }, 2000)
            })
        }
    }

    handleFilterResponse = (apiCallId: string, responseJson: any) => {
        if (apiCallId === this.getAccountNameId) {
            this.setState({ listAccountName: [...responseJson] })
        }
        if (apiCallId === this.getAuditorId) {
            const listAuditor = responseJson.data.map((auditor: AuditorType) => {
                return {id: auditor.id, full_name: auditor.attributes?.full_name, email: auditor.attributes?.email}})
            this.setState({ listAuditor: [...listAuditor] })
        }
    }

    handleAuditListResponse = (responseJson: any) => {
        if (responseJson.audits) {
            this.setState({
                tableData: responseJson.audits.data,
                isLoading: false,
                totalPage: Math.ceil(responseJson.totalRecords / responseJson.recordsPerPage),
            })
        }
    }

    handleError = (errors: Array<object>) => {
        const list: { [key: string]: string } = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            list[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if (list['token']) this.gotoLogin()
    }

    gotoLogin = async () => {
        await setStorageData("name", "AuditList")
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    handleRoleResponse = async (roleData: { id: string, role: string }) => {
        this.setState({ userRole: { ...roleData, group: this.getUserGroup(roleData.role) }, isPageLoading: false }, async () => {
            let selectedStatus: any = []
            if(this.state.userRole.group !== 'evermore') selectedStatus = ['Completed']
            const searchKey = this.getSearchKey()
            const status = await getStorageData("status")
            this.setState({ selectedStatus: status? status.split(","): selectedStatus }, () => { removeStorageData("status") })
            if (searchKey && searchKey !== '') {
                await this.searchAudit(searchKey, 1)
            } else {
                await this.getAuditList()
            }
            await this.getFilterData()

        })
    }

    getUserGroup = (role: string) => {
        const evermoreUserGroup = ['All Access User', 'Auditor', 'Account Manager', 'Team Leader']
        if (evermoreUserGroup.includes(role)) return 'evermore'
        return 'client'
    }

    apiCall = async (params: { endpoint: string, method: string, body?: object }) => {
        const token = await getStorageData("authToken");
        const { endpoint, method, body } = params
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": 'application/json',
                token
            })
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        body && reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    getFilterData = async () => {
        this.getAccountNameId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: configJSON.getAccountNameEndpoint
        })
        this.getAuditorId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.filterAccountEndpoint}?attribute_value=assigned_to`
        })
    }

    searchAudit = async (searchKey: string, page: number) => {
        const endpoint = this.getFilterEndpoint()

        this.setState({ currentPage: page })
        this.getAuditListCallId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.searchAuditEndpoint}?search_query=${searchKey}&page=${page}${endpoint}`
        })
    }

    gotoAuditInfo = (id: number) => {
      this.props.navigation.navigate("AuditInfo", {id})
    }

    getAuditList = async () => {
        const endpoint = this.getFilterEndpoint()
        this.getAuditListCallId = await this.apiCall({
            endpoint: `${configJSON.getAuditListEndpoint}?${endpoint}`,
            method: configJSON.getApiMethod
        })
    }

    getAuditListWithFilter = async (page: number, endpoint: string) => {
        this.getAuditListCallId = await this.apiCall({
            endpoint: `${configJSON.getAuditListEndpoint}?page=${page}${endpoint}`,
            method: configJSON.getApiMethod
        })
    }

    getUserRole = async () => {
        this.getUserRoleCallId = await this.apiCall({
            endpoint: configJSON.getUserRoleEndpoint,
            method: configJSON.getApiMethod
        })
    }

    handleDeleteRecord = async () => {
        this.setState({isOpenModal: false})
        this.deleteRecordId = await this.apiCall({
            method: configJSON.deleteApiMethod,
            endpoint: `${configJSON.updateAuditEndpoint}/${this.state.edittedRecord.id}`
        })
    }

    handleSaveEditRecord = async () => {
        const {edittedRecord} = this.state

        const body = {
            audit: {
                auditor_id: edittedRecord.auditor.id,
                status: edittedRecord.attributes.status
            }
        }
        this.setState({isOpenModal: false})
        this.updateRecordId = await this.apiCall({
            method: configJSON.putApiMethod,
            endpoint: `${configJSON.updateAuditEndpoint}/${edittedRecord.id}`,
            body
        })

    }

    removeDateRangePicker = async () => {
        this.setState({ fromDate: null, toDate: null })
        await this.filterData(1)
    }

    getFilterEndpoint = () => {
        let endpoint = "";
        const {isDateCompletedAsc, hasCap, isDateCreatedAsc, selectedAccountName, selectedAuditNumber, selectedAuditor, selectedStatus} = this.state;
        const selectedAccountNameKey = Object.keys(selectedAccountName ?? {})
        const selectedAuditorKey = Object.keys(selectedAuditor ?? {})

        if(isDateCompletedAsc !== null) endpoint += isDateCompletedAsc ? '&sort_by=date_completed_asc':'&sort_by=date_completed_desc'
        if(isDateCreatedAsc !== null) endpoint += isDateCreatedAsc ? '&sort_by=date_created_asc':'&sort_by=date_created_desc'
        for(let item in selectedStatus) endpoint+=`&filter[status][]=${statusMapping[selectedStatus[item] as keyof typeof statusMapping]}`
        if(selectedAuditNumber?.length > 0) endpoint+=`&filter[audit_number]=${selectedAuditNumber}`
        if(selectedAccountNameKey?.length > 0) endpoint+=`&filter[account_name]=${selectedAccountName.name}`
        if(selectedAuditorKey?.length > 0) endpoint+=`&filter[assigned_to]=${selectedAuditor.full_name}`
        if(hasCap) endpoint+=`&filter[corrective_action_plan]=true`

        return endpoint
    }

    filterData = async (page: number) => {
        const endpoint = this.getFilterEndpoint()

        this.setState({ currentPage: page })
        await this.getAuditListWithFilter(page, endpoint)
    }

    handleChangeFromDate = async (fromDate: Date) => {
        const { toDate } = this.state
        this.setState({ fromDate })
        if (toDate) {
            const endpoint = `&from=${fromDate}&to=${toDate}`
            await this.getAuditListWithFilter(1, endpoint)
        }
    }
    handleChangeToDate = async (toDate: Date) => {
        const { fromDate } = this.state
        this.setState({ toDate })
        if (fromDate) {
            const endpoint = `&from=${fromDate}&to=${toDate}`
            await this.getAuditListWithFilter(1, endpoint)
        }
    }

    onChangePage = (currentPage: number) => {
        const {searchKey} = this.state;
        if(searchKey !== "") this.searchAudit(searchKey, currentPage)
        else this.filterData(currentPage)
        this.setState({ currentPage })
    }
    getStatusStyle = (status: string) => {
        let color, backgroundColor, text = "no status", smallText;
        if (status === 'assigned') {
            color = "#DC2626";
            backgroundColor = "#FEE2E2"
            text = "Assigned"
            smallText = "A"
        }

        if (status === 'under_review') {
            color = "#D97706";
            backgroundColor = "#FEF3C7"
            text = "Under Review"
            smallText = "P"
        }

        if (status === 'completed') {
            color = "#059669";
            backgroundColor = "#D1FAE5"
            text = "Completed"
            smallText = "C"
        }
        return { color, backgroundColor, text, smallText }
    }

    onChangeAuditNumber = (selectedAuditNumber: string) => {
        clearTimeout(this.auditNumberTimeout)
        this.auditNumberTimeout = setTimeout(() => {
                this.filterData(1)
        }, 500)
        
        this.setState({ selectedAuditNumber })
    }
    onChangeListStatus = (selectedItem: StatusType) => {
        const { selectedStatus } = this.state
        if (!selectedStatus.includes(selectedItem)) selectedStatus.push(selectedItem)
        else {
            const itemIdx = selectedStatus.indexOf(selectedItem);
            selectedStatus.splice(itemIdx, 1)
        }
        clearTimeout(this.statusTimeout)
        this.statusTimeout = setTimeout(() => {
                this.filterData(1)
        }, 500)
        this.setState({ selectedStatus })
    }
    onChangeAccountName = (value: any) => {
        clearTimeout(this.accountNameTimeout)
        this.accountNameTimeout = setTimeout(() => {
                this.filterData(1)
        }, 500)
        this.setState({selectedAccountName: value})
    }
    onChangeAuditor = (value: any) => {
        clearTimeout(this.auditorTimeout)
        this.auditorTimeout = setTimeout(() => {
                this.filterData(1)
        }, 500)
        this.setState({selectedAuditor: value})
    }
    onChangeDateCreated = () => {
        this.setState({
            isDateCreatedAsc: !this.state.isDateCreatedAsc,
            isDateCompletedAsc: null
        }, () => {
            this.filterData(1)
        })
    }
    onChangeDateCompleted = () => {
        this.setState({
            isDateCompletedAsc: !this.state.isDateCompletedAsc,
            isDateCreatedAsc: null
        }, () => {
            this.filterData(1)
        })
    }
     
    getDateCompletedIcon = () => {
        if(this.state.isDateCompletedAsc === null) return icSort
        return this.state.isDateCompletedAsc ? icSortAsc : icSortDesc
    }

    getDateCreatedIcon = () => {
        if(this.state.isDateCreatedAsc === null) return icSort
        return this.state.isDateCreatedAsc ? icSortAsc : icSortDesc
    }

    handleCancelFilter = () => {
        this.setState({
          selectedAccountName: {},
          selectedAuditor: {},
          selectedStatus: [],
          selectedAuditNumber: "",
          currentPage: 1,
          isDateCompletedAsc: null, 
          isDateCreatedAsc: null,
          hasCap: false
        }, async () => {
            await this.filterData(this.state.currentPage)
        })
    }

    openEditModal = (event: React.MouseEvent<HTMLElement, MouseEvent>, auditData: AuditDetails) => {
        event?.stopPropagation()
        const {attributes} = auditData
        this.setState({
            edittedRecord: {
                ...this.state.edittedRecord,
                id: auditData.id,
                type: auditData.type,
                attributes: {...auditData.attributes}, 
                auditor: {id: attributes.auditor_id, full_name: attributes.assigned_to, email: attributes.assigned_to }}
        },()=>{
          this.setState({ isOpenModal: true})
        });
      };

    handleCloseModal = () => {
        this.setState({ isOpenModal: false, edittedRecord: sampleEdittedRecord})
    }

    handleEditAssignTo = (value: AuditorType) => {
        const {edittedRecord} = this.state;
        this.setState({edittedRecord: {...edittedRecord, auditor: value}})
    }
    closeNotiToast = () => {
        this.setState({isOpenNotiToast: false})
    }
    isShowButtonCancel = () => {
        const {isDateCompletedAsc, isDateCreatedAsc, selectedAccountName, selectedAuditNumber, selectedAuditor, selectedStatus} = this.state;
        const selectedAccountNameKey = Object.keys(selectedAccountName ?? {})
        const selectedAuditorKey = Object.keys(selectedAuditor ?? {})
        return isDateCompletedAsc || isDateCreatedAsc || selectedAccountNameKey.length > 0 || selectedAuditorKey.length > 0 
        || selectedAuditNumber !== "" || selectedStatus.length > 0
    }
}
// Customizable Area End
