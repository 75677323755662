// Customizable Area Start
import React from "react";

import {
    ThemeProvider,
    Typography,
    createTheme,
    Button,
    styled,
    Container,
    Box,
    Input,
    Select,
    MenuItem,
    IconButton,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
} from "@material-ui/core";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiContainer: {
            root: {
                padding: 0
            }
        },
        MuiSelect: {
            icon: {
                right: 10
            }
        }
    }
})


import ReportController, {
    OptionType,
    Props,
    configJSON,
    FormField,
    Field,
    QuestionImage,
} from "./ReportController";
import { icUpload } from "./assets";
import Header from "../../../blocks/navigationmenu/src/Header.web";

export default class Report extends ReportController {
    constructor(props: Props) {
        super(props);
    }

    ZoomImage = () => {
        return <Dialog fullWidth open={this.state.openZoomImage} onClose={this.closeZoomImage}>
        <DialogContent dividers>
            <CloseIcon data-test-id="btn-close-zoom-image" onClick={this.closeZoomImage} style={styles.iconClose as React.CSSProperties} />
            <Box style={styles.dlogContent as React.CSSProperties}>
               <img src={this.state.zoomImg.source} style={styles.zoomImg} alt="" />
            </Box>
        </DialogContent>
    </Dialog>
    }

    QuestionItem = (index: number, item: FormField) => {
        const {group, role} = this.state.user
        const {auditStatus} = this.state
        const isDisable = (group !== 'evermore') || (auditStatus === 'assigned' && role !== 'Auditor') || (auditStatus === 'completed') || (auditStatus === 'under_review' && role==='Auditor')
        return item.fields.map((element: Field, subIndex) => <Box key={"question-"+element.id} style={styles.questionContainer as React.CSSProperties}>
            <Box style={{ ...styles.flexBox, justifyContent: 'space-between' }}>
                <TypographyContent>{subIndex + 1}. {element.field_type}</TypographyContent>
                <TypographyContent style={{ whiteSpace: 'nowrap' }}>{element.points} Points</TypographyContent>
            </Box>
            <Box>
                {this.state.callFormSubmitted && !element.field_value &&
                <TypographyContent style={{fontSize: 14, color: "#DC2626"}}>*{configJSON.required}</TypographyContent>}
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    style={{ ...styles.answerItem, width: '100%' }}
                    value={element.field_value ?? ""}
                    disableUnderline
                    disabled={isDisable}
                    MenuProps={{
                        TransitionProps: { style: { padding: "0 4px", marginTop: 15, borderRadius: 8 } },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        getContentAnchorEl: null
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                >
                    {element.field_options?.split(", ").map((option) =>
                        <MenuItem key={element.id + option} data-test-id={`option-${option.toLowerCase()}`}
                            onClick={() => { this.onChangeOption(item.id, element.id, option as OptionType) }}
                            value={option}>{option}</MenuItem>
                        )
                    }
                </Select>
            </Box>
            <Box style={{ ...styles.flexBox, flexDirection: 'column', alignItems: 'start' }}>
                <TypographyContent>{configJSON.notes}:</TypographyContent>
                <CustomInput
                    key={'input'+item.id}
                    data-test-id="inputComment"
                    placeholder={"Placeholder"}
                    fullWidth={true}
                    value={element.field_text ?? ""}
                    onBlur={() => { this.onSaveNotes() }} 
                    onChange={(event) => { this.onChangeNotes(item.id, element.id, event.target.value) }}
                    disableUnderline
                    disabled={isDisable} />
            </Box> 
            <Box style={{ ...styles.questionContainer, flexDirection: 'row' } as React.CSSProperties}>
                <input
                    ref={this.imgRef}
                    multiple
                    type="file"
                    id="file"
                    data-test-id="input-upload-img"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => this.onChangeFile(e.target.files)}
                    style={{ display: 'none' }}
                    disabled={isDisable}
                />
                <CustomButton disabled={isDisable} data-test-id="btn-upload-img" onClick={() => this.onClickUploadPhoto(item.id, element.id)}>
                    <img src={icUpload} alt="" />
                    {configJSON.uploadPhoto}</CustomButton>
                <CustomButton disabled={isDisable} data-test-id="btn-open-camera" onClick={() => this.handleOpenCamera(item.id, element.id)}>
                    <CameraAltOutlinedIcon />
                    {configJSON.takePhoto}
                </CustomButton>
            </Box>
            <Box style={{ ...styles.flexBox, gap: 20, overflowX: 'auto', padding: 10 }}>
                {element.images?.map((image: QuestionImage) =>
                    <ImageContainer data-test-id="image-container" onClick={() => { this.openZoomImage(image.id, element.id, item.id) }} key={image.id}>
                        <img src={image.source} alt="" style={styles.image as React.CSSProperties} />
                        <CustomIconButton disabled={isDisable} data-test-id="btn-remove-img"
                            onClick={(event) => this.removeImageItem(event, image.id, item.id, element.id)}
                            style={styles.iconClose as React.CSSProperties}>
                            <CloseIcon style={{ color: '#0F172A' }} />
                        </CustomIconButton>
                    </ImageContainer>)}
                {element.isLoadingImage && <CircularProgress />}
            </Box>
        </Box>)
    }

    LoadingMask = () => {
        return <Dialog PaperProps={{style: {background: 'none', boxShadow: 'none'}}} fullWidth open={this.state.isLoadingSubmit}>
        <DialogContent dividers>
                <Box style={{ ...styles.dlogContent, marginTop: 20, marginBottom: 30 } as React.CSSProperties}>
                    <CircularProgress style={{ color: "#34D399", height: 50, width: 50 }} />
                    <Typography align="center" style={{ ...styles.successText, marginTop: 10, color: '#FFFFFF' }}>
                        {configJSON.isLoadingMessage}</Typography>
                </Box>
        </DialogContent>
    </Dialog>
    }
    
    DialogSuccess = () => {
        return <Dialog fullWidth open={this.state.isOpenDialog} onClose={this.onToggleDialog}>
            <DialogContent dividers>
                <CloseIcon data-test-id="btn-close-dialog" onClick={this.onToggleDialog} style={styles.iconClose as React.CSSProperties} />
               
                    <Box style={{ ...styles.dlogContent, marginTop: 20, marginBottom: 30 } as React.CSSProperties}>
                        <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
                        <Typography align="center" style={{ ...styles.successText, marginTop: 10 }}>
                            {configJSON.saveFormSuccessMsg}</Typography>
                    </Box>
            </DialogContent>
        </Dialog>
    }

    render() {
        const {group, role} = this.state.user
        const {auditStatus} = this.state
        const isDisabledButtonDone = (group !== 'evermore') || (auditStatus === 'assigned' && role !== 'Auditor') || (auditStatus === 'completed') || (auditStatus === 'under_review' && role==='Auditor')
        const isShowButtonCancel = this.state.auditStatus === 'under_review' && group === 'evermore' && role !== 'Auditor'
        return <Header navigation={this.props.navigation} >
            <ThemeProvider theme={theme}>
                <CustomContainer maxWidth={false} style={{ overflowY: this.state.isOpenCamera ? 'hidden' : 'auto' } as React.CSSProperties}>
                    <ContentBox>
                        <Grid container>
                            <Grid item xs={12} lg={8}>
                                <Title>{configJSON.foodSafety}:</Title>
                                <GroupButton>
                                    {this.state.listTabs.map((tab, idx) =>
                                        <TabButton key={tab + idx} data-test-id={"section-" + tab}
                                            onClick={() => this.onChangeTab(tab)} className={this.isActiveTab(tab)}>
                                            <Typography className="section">{tab}</Typography>
                                        </TabButton>)}
                                </GroupButton>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <PointContainer>
                                    <Box className="text" style={{ lineHeight: '32px' }}>
                                        <TypographyContent className="bold" component={"span"}>
                                            {configJSON.totalPoints}
                                        </TypographyContent>
                                        : <TypographyContent component={"span"}>{configJSON.givenPoints} / {configJSON.applicablePoints}</TypographyContent>
                                    </Box>
                                    <Box className="text">
                                        <TypographyContent className="bold" component={"span"}>
                                            {configJSON.totalPoints}
                                        </TypographyContent>
                                        : <TypographyContent component={"span"}>{this.state.points}</TypographyContent>
                                    </Box>
                                </PointContainer>
                            </Grid>

                        </Grid>

                        {this.state.isLoading ? <CircularProgress /> :
                            <Box style={{ padding: 0 }}>
                                {this.state.originalSubsections.map((item, index) => <Box key={item.name + item.id}>
                                    <HeadingContainer><Typography className="sub-section">{item.name}</Typography></HeadingContainer>
                                    {this.QuestionItem(index, item)}
                                </Box>)}
                                <Box style={{ ...styles.flexBox, justifyContent: 'end', gap: 20 }}>
                                    {isShowButtonCancel && <CustomButton data-test-id="btn-reset" onClick={this.handleCancelForm} className="cancel">{configJSON.cancel}</CustomButton>}
                                    {!isDisabledButtonDone && <CustomButton data-test-id="btn-done" onClick={this.handleSaveForm} className="done">{configJSON.done}</CustomButton>}
                                </Box>
                            </Box>}
                    </ContentBox>
                    {this.state.isOpenCamera &&
                        <Camera
                            data-test-id="test-camera"
                            onTakePhoto={(dataUri) => {
                                this.handleTakePhoto(dataUri);
                            }}
                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                            idealResolution={{ width: 480, height: 640 }}
                            imageType={IMAGE_TYPES.JPG}
                            imageCompression={0.97}
                            isMaxResolution={true}
                            isSilentMode={false}
                            isFullscreen={false}
                            onCameraError={() => { this.onCloseCamera() }}
                        />
                    }
                </CustomContainer>
                {this.DialogSuccess()}
                {this.LoadingMask()}
                {this.ZoomImage()}
            </ThemeProvider>
        </Header>

    }
}

const styles = {
    flexBox: {
        display: "flex",
        alignItems: "center"
    },
    answerItem: {
        justifyContent: 'space-between',
        border: '1px solid #CBD5E1',
        padding: '10px 8px',
        borderRadius: 8,

    },
    zoomImg: {
        width: "100%",
        height: 'auto',
        objectFit: 'cover', 
        objectPosition: 'center'
    } as React.CSSProperties,
    questionContainer: {
        marginTop: 15,
        marginBottom: 15,
        gap: 10,
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    image: {
        width: 121,
        height: 121,
        objectFit: 'contain'
    },
    iconClose: {
        position: 'absolute',
        right: 10,
        top: 10,
        border: '1px solid black',
        background: '#FFFFFF',
        padding: 3,
        borderRadius: 100,
        cursor: "pointer"
    },
    dlogContent: { 
        flexDirection: "column", 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
      },
      successText: {
        color: "#0F172A",
        fontWeight: 700,
        lineHeight: "32px",
        fontSize: 24,
      },
}

const PointContainer = styled(Box)({
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'end',
    '& .text': {
        textAlign: 'end'
    },
    // "@media (max-width: 1178px) and (min-width: 940px)": {
    //     alignItems: 'start',
    //     '& .text': {
    //         textAlign: 'start'
    //     }
    // },
    "@media (max-width: 1280px)": {
        gap: 5,
        alignItems: 'start',
        '& .text': {
            textAlign: 'start'
        }
    }
})

const ImageContainer = styled(Box)({
    width: 168,
    minWidth: 168,
    height: 160,
    boxShadow: "0px 4px 8px 0px #00000033",
    position: 'relative',
    background: '#FFFFFF',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
})

const CustomInput = styled(Input)({
    border: "1px solid #CBD5E1",
    padding: 10,
    borderRadius: 8,
    marginTop: 10
})

const CustomIconButton  = styled(IconButton)({
    "&:disabled": {
        opacity: '0.5'
    }
})

const CustomContainer = styled(Container)({
    minHeight: "calc(100vh - 80px)",
    height: "auto",
    position: 'relative',
    padding: 0,
    overflowX: 'hidden',
    
    "& .react-html5-camera-photo": {
        position: 'fixed',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        "&>video": {
            position: 'fixed',
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: 'cover',
        }
    }
})

const TitleGroup = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    "@media (max-width: 1178px) and (min-width: 940px)": {
        flexDirection: 'column',
    },
    "@media (max-width: 820px)": {
        flexDirection: 'column',
    }
})

const GroupButton = styled(Box)({
    boxShadow: "0px 4px 4px 0px #00000040",
    width: "fit-content",
    gap: 10,
    borderRadius: 50,
    padding: 5,
    display: 'flex',
    marginBottom: 20,
    "@media (max-width: 475px)": {
        flexWrap: 'wrap',
        boxShadow: 'none'
    }
})
const TabButton = styled(Box)({
    border: "1px solid black",
    padding: "10px 12px",
    textTransform: "capitalize",
    color: "#647488",
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    '& .section': {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
        textTransform: 'uppercase'
    },
    "&.active": {
        backgroundColor: "#A0A2FF",
        color: "#152764",
        border: "1px solid #767BD7"
    },
    "@media (max-width: 576px)": {
        '& .section': {
            fontSize: 14,
        }
    }
})

const HeadingContainer = styled(Box)({
    padding: '10px 8px',
    border: '1px solid #CBD5E1',
    borderRadius: 8,
    backgroundColor: '#E5E5FF',
    '& .sub-section': {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: '28px',
        textTransform: 'uppercase',
        "@media (max-width: 576px)": {
            fontSize: 16,
        }
    }
})

const CustomButton = styled(Button)({
    padding: "10px 16px",
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: 8,
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 700,
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    color: "#FFFFFF",
    whiteSpace: 'nowrap',
    '&:disabled': {
        opacity: '0.5',
        color: "#FFFFFF !important"
    },
    '&.done': {
        background: "#34D399",
    },
    '&.cancel': {
        background: '#F87171',
    },
    '& svg, img': {
        marginRight: 5
    },
    "@media (max-width: 576px)": {
        fontSize: 14,
    },
    "@media (max-width: 425px)": {
        fontSize: 12,
    }
})

const ContentBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    margin: "30px",
    padding: 30,
    border: '1px solid #CBD5E1',
    gap: 20,
    height: "auto",
    position: 'relative',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    "@media (max-width: 576px)": {
        padding: "20px 10px",
        margin: 20
    }
})

const Title = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 24,
    lineHeight: "32x",
    fontWeight: 700,
    marginBottom: 20,
    "@media (max-width: 576px)": {
        fontSize: 20,
    }
})

const TypographyContent = styled(Typography)({
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    "&.bold": {
        fontWeight: 700
    },
    "@media (max-width: 576px)": {
        fontSize: 14,
    }
}) as typeof Typography
// Customizable Area End
